import { defineStore } from 'pinia';
import { ref } from 'vue';

// export const useGlobalStore = defineStore({
//   id: 'global',
//   state: () => {
//     return {
//       loading: true,
//     };
//   },
//   actions: {
//     setLoading(loading: boolean) {
//       this.loading = loading;
//     },
//   },
// });

export const useGlobalStore = defineStore('global', () => {
  /** Have all stores been hydrated. This indicates that the app is ready to be rendered */
  const hydrated = ref(false);

  /** Stores are currently being hydrated */
  const hydrating = ref(false);

  /** Has the app been hydrated at least once */
  const hasBeenHydrated = ref(false);

  /** Global hydration error. App should not be rendered */
  const error = ref(null);

  /** Stores the main div ref. Useful for scroll animations */
  const main = ref<Element | null>(null);

  return {
    hydrated,
    hydrating,
    hasBeenHydrated,
    error,
    main,
  };
});
