import type { Session, SessionNoteType } from '@tu/types/session';
import { api } from '@tu/utilities/api';
import { defineStore } from 'pinia';
import { type RouteLocationNormalized } from 'vue-router';
import { useKeyNoteStore, useUserStore } from '.';

export const useSessionStore = defineStore({
  id: 'session',
  state: () => ({
    loading: false,
    error: null as string | null,
    sessions: [] as Session[],
  }),
  actions: {
    async hydrate(to: RouteLocationNormalized | null = null) {
      this.loading = true;
      try {
        const response = await api.get(`/api/sessions`);

        const { sessions } = response.data;
        this.sessions = sessions;
      } catch (error: any) {
        if (error.response.status !== 401) {
          // this.error = error;
        }
      } finally {
        this.loading = false;
      }
    },
    async dehydrate() {
      this.$reset();
    },
    findSession(uuid: string): Session | undefined {
      return this.sessions.find((session) => session.uuid === uuid);
    },
    async create(): Promise<Session | null> {
      const userStore = useUserStore();
      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return null;
      }

      // Save the session
      this.loading = true;

      try {
        const response = await api.post(`/api/sessions`, {
          participant_uuid: userStore.currentParticipant.uuid,
          relationship_uuid: userStore.currentRelationship.uuid,
        });

        const { session } = response.data;

        // Add the session to the store
        this.sessions.push(session);

        this.loading = false;

        return session;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

      return null;
    },
    async update(session_uuid: string, payload: { date?: string }): Promise<Session | null> {
      const userStore = useUserStore();
      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return null;
      }

      // Save the session
      this.loading = true;

      try {
        const response = await api.patch(`/api/sessions/${session_uuid}`, {
          ...payload,
        });

        const { session } = response.data;

        // Update the session in the store
        const index = this.sessions.findIndex((session) => session.uuid === session_uuid);
        if (index !== -1) {
          this.sessions[index] = session;
        } else {
          this.sessions.push(session);
        }

        this.loading = false;

        return session;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

      return null;
    },
    async attachKeyNote(session_uuid: string, key_note_uuid: string): Promise<Session | null> {
      const userStore = useUserStore();
      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return null;
      }

      // Save the session
      this.loading = true;

      try {
        const response = await api.patch(`/api/sessions/${session_uuid}/attach-keynote`, {
          key_note_uuid,
        });

        const { session } = response.data;

        // Update the session in the store
        const index = this.sessions.findIndex((session) => session.uuid === session_uuid);
        if (index !== -1) {
          this.sessions[index] = session;
        } else {
          this.sessions.push(session);
        }

        // Also need to update the keynote in the keynote store
        const keyNoteStore = useKeyNoteStore();
        keyNoteStore.updateKeyNote(key_note_uuid, { session_uuid });

        this.loading = false;

        return session;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

      return null;
    },
    async removeKeyNote(session_uuid: string, key_note_uuid: string): Promise<Session | null> {
      const userStore = useUserStore();
      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return null;
      }

      // Save the session
      this.loading = true;

      try {
        const response = await api.patch(`/api/sessions/${session_uuid}/remove-keynote`, {
          key_note_uuid,
        });

        const { session } = response.data;

        // Update the session in the store
        const index = this.sessions.findIndex((session) => session.uuid === session_uuid);
        if (index !== -1) {
          this.sessions[index] = session;
        } else {
          this.sessions.push(session);
        }

        // Also need to update the keynote in the keynote store
        const keyNoteStore = useKeyNoteStore();
        keyNoteStore.updateKeyNote(key_note_uuid, { session_uuid: null });

        this.loading = false;

        return session;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

      return null;
    },
    async addNote(
      session_uuid: string,
      payload: {
        content: string;
        is_shared: boolean;
        type: SessionNoteType;
      },
    ): Promise<Session | null> {
      const userStore = useUserStore();
      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return null;
      }

      // Save the session
      this.loading = true;

      try {
        const response = await api.post(`/api/sessions/${session_uuid}/note`, {
          ...payload,
          participant_uuid: userStore.currentParticipant.uuid,
        });

        const { session } = response.data;

        // Update the session in the store
        const index = this.sessions.findIndex((session) => session.uuid === session_uuid);
        if (index !== -1) {
          this.sessions[index] = session;
        } else {
          this.sessions.push(session);
        }

        this.loading = false;

        return session;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

      return null;
    },
    async removeNote(session_uuid: string, note_uuid: string): Promise<Session | null> {
      const userStore = useUserStore();
      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return null;
      }

      // Save the session
      this.loading = true;

      try {
        const response = await api.delete(`/api/sessions/${session_uuid}/note/${note_uuid}`);

        const { session } = response.data;

        // Update the session in the store
        const index = this.sessions.findIndex((session) => session.uuid === session_uuid);
        if (index !== -1) {
          this.sessions[index] = session;
        } else {
          this.sessions.push(session);
        }

        this.loading = false;

        return session;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

      return null;
    },
    async updateNote(
      session_uuid: string,
      note_uuid: string,
      payload: {
        content?: string;
        is_shared?: boolean;
      },
    ) {
      try {
        const response = await api.patch(`/api/sessions/${session_uuid}/note/${note_uuid}`, {
          ...payload,
        });

        const { session } = response.data;

        // Update the session in the store
        const index = this.sessions.findIndex((session) => session.uuid === session_uuid);
        if (index !== -1) {
          this.sessions[index] = session;
        } else {
          this.sessions.push(session);
        }

        this.loading = false;

        return session;
      } catch (error) {
        console.log(error);
      }

      return null;
    },
  },
  getters: {
    hasUndatedSessions(): boolean {
      const userStore = useUserStore();
      if (!userStore.currentRelationship) {
        return false;
      }

      return this.sessions.some(
        (session) => !session.date && session.relationship_uuid === userStore.currentRelationship?.uuid,
      );
    },
    undatedSession(): Session | null {
      const userStore = useUserStore();
      if (!userStore.currentRelationship) {
        return null;
      }

      return (
        this.sessions.find(
          (session) => !session.date && session.relationship_uuid === userStore.currentRelationship?.uuid,
        ) || null
      );
    },
    currentSessions(): Session[] {
      const userStore = useUserStore();
      if (!userStore.currentRelationship) {
        return [];
      }

      return this.sessions.filter((session) => session.relationship_uuid === userStore.currentRelationship?.uuid);
    },
  },
});
