import type { Key, KeyJourney, KeyPack } from '@tu/types/key';
import { api } from '@tu/utilities/api';
import { defineStore } from 'pinia';
import { type RouteLocationNormalized } from 'vue-router';
import { useUserStore } from '.';

export const useKeyStore = defineStore({
  id: 'key',
  state: () => ({
    loading: false,
    error: null as string | null,
    keys: [] as Key[],
    packs: [] as KeyPack[],
    tags: [] as string[],
    journeys: [] as KeyJourney[],
  }),
  actions: {
    async hydrate(to: RouteLocationNormalized | null = null) {
      this.loading = true;
      try {
        const response = await api.get(`/api/keys`);

        const { keys, packs, tags, journeys } = response.data;
        this.keys = keys;
        this.packs = packs;
        this.tags = tags;
        this.journeys = journeys;
      } catch (error: any) {
        if (error.response.status !== 401) {
          // this.error = error;
        }
      } finally {
        this.loading = false;
      }
    },
    async dehydrate() {
      this.$reset();
    },
    findKey(uuid: string): Key | undefined {
      return this.keys.find((key) => key.uuid === uuid);
    },
    findKeyPack(uuid: string): KeyPack | undefined {
      return this.packs.find((pack) => pack.uuid === uuid);
    },
    findKeyBlock(key: Key, block_uuid: string) {
      return key.sections
        .flatMap((section) => section.pages.flatMap((page) => page.blocks))
        .find((block) => block.uuid === block_uuid);
    },
    findJourney(participant_uuid: string, relationship_uuid: string) {
      return this.journeys.find(
        (journey) => journey.participant_uuid === participant_uuid && journey.relationship_uuid === relationship_uuid,
      );
    },
    async generateKeyJourney(answer: string): Promise<
      | {
          success: false;
        }
      | {
          journey: KeyJourney;
          success: true;
        }
    > {
      const userStore = useUserStore();
      if (!userStore.currentParticipant || !userStore.currentRelationship)
        return {
          success: false,
        };

      const response = await api.post(`/api/key-journey`, {
        participant_uuid: userStore.currentParticipant.uuid,
        relationship_uuid: userStore.currentRelationship.uuid,
        answer,
      });

      return response.data;
    },
    async confirmKeyJourney(journey: KeyJourney) {
      try {
        const response = await api.patch(`/api/key-journey/${journey.uuid}/confirm`);

        const { journey: confirmedJourney } = response.data;

        this.journeys.push(confirmedJourney);
      } catch (error: any) {
        console.log(error);
      }
    },
  },
  getters: {},
});
