import * as Sentry from '@sentry/vue';
import router, { rehydrate } from '@tu/router';
import type { Program } from '@tu/types/program';
import type { ProfileFields, Role, User } from '@tu/types/user';
import { api, apiRetry } from '@tu/utilities/api';
import { beaconIdentify } from '@tu/utilities/helpscout';
import { getRoleLabel } from '@tu/utilities/language';
import { parseISO } from 'date-fns';
import { defineStore } from 'pinia';
import { type RouteLocationNormalized } from 'vue-router';
import type { Participant, Partner, Relationship } from '../types/participant';
import type { Survey } from '../types/survey';
import type { UserField } from '../types/instance';

export const useUserStore = defineStore({
  id: 'user',
  state: () => {
    let initialSelectedState = {
      program: null as string | null,
      participant: null as string | null,
      relationship: null as string | null,
    };

    const storedSelectedState = localStorage.getItem('selected');
    if (storedSelectedState) {
      initialSelectedState = JSON.parse(storedSelectedState);
    }

    return {
      user: null as User | null,
      loading: false,
      error: null as string | null,
      programs: [] as Program[],
      selected: initialSelectedState,
      session: {} as {
        impersonator?: string;
      },
      surveyOpen: false,
      register: {
        email: '',
        code: '',
        verified: false,
        loading: false,
        invalid: false,
        registered: false,
      },
      sessionTimer: null as any,
      sessionExpired: false,
    };
  },
  actions: {
    async hydrate(to: RouteLocationNormalized | null = null) {
      this.loading = true;
      try {
        await api.get(`/sanctum/csrf-cookie`);
        const response = await api.get(`/api/auth`);

        const { user, programs, session } = response.data;
        this.user = user;
        this.programs = programs;
        this.session = session;

        if (this.currentSurvey && this.currentParticipant?.is_tutorial_complete) {
          this.surveyOpen = true;
        }

        await this.startRefreshSessionTimer();

        Sentry.setUser({
          id: user.uuid,
          email: user.email,
        });

        beaconIdentify({
          name: user.name,
          email: user.email,
          role: this.currentParticipant?.role,
        });

        // If the selected state is set, ensure that the selected program, participant, and relationship are valid
        if (this.selected.program) {
          const program = this.programs.find((program) => program.uuid === this.selected.program);
          if (!program) {
            this.selectProgram(null);
          }
        }
        if (this.selected.participant) {
          const participant = this.currentProgram?.participants.find(
            (participant) => participant.uuid === this.selected.participant,
          );
          if (!participant) {
            this.selectParticipant(null);
          }
        }
        if (this.selected.relationship) {
          const relationship = this.currentParticipant?.relationships.find(
            (relationship) => relationship.uuid === this.selected.relationship,
          );
          if (!relationship) {
            this.selectRelationship(null);
          }
        }

        // Lastly, if there are no selections and this user has access to only one program, participant and relationship, select them
        if (!this.selected.program && !this.selected.participant && !this.selected.relationship) {
          if (this.programs.length === 1) {
            const program = this.programs[0];
            if (program.participants.length === 1) {
              const participant = program.participants[0];
              if (participant.relationships.length === 1) {
                const relationship = participant.relationships[0];
                this.selectProgram(program.uuid);
                this.selectParticipant(participant.uuid);
                this.selectRelationship(relationship.uuid);
              }
            }
          }
        }
      } catch (error: any) {
        if (error.response.status !== 401) {
          // this.error = error;
        }
      } finally {
        this.loading = false;
      }
    },
    async dehydrate() {
      this.$reset();
    },
    async login(payload: { email: string; password: string }, redirect?: string) {
      this.error = null;
      console.log('logging in');
      try {
        await api.get(`/sanctum/csrf-cookie`);
        const result = await api.post('/api/login', payload);

        console.log(result.data);

        await rehydrate();
        await this.startRefreshSessionTimer();

        if (['admin', 'program_admin', 'org_rep'].includes(result?.data?.role)) {
          const destination = localStorage.getItem('redirect') ?? undefined;

          if (!destination || !destination.startsWith('/invite')) {
            await this.redirect('/portal');
            return;
          } else {
            await this.redirect(destination);
            return;
          }
        } else {
          await this.redirect(redirect);
        }
      } catch (error: any) {
        console.log(error);
        this.error = `Invalid credentials, please try again.`;
      }
    },
    async logout() {
      await api.post('/api/logout');
      this.$reset();

      if (router.currentRoute.value.name !== 'login') {
        router.push({ name: 'login' });
      }
    },
    async getCurrentUser() {
      try {
        await api.get('/sanctum/csrf-cookie');
        const response = await api.get('/api/auth');

        const { user, programs, session } = response.data;
        this.user = user;
        this.programs = programs;
        this.session = session;
      } catch (error) {
        this.user = null;
        console.log(error);
      }
      return this.user;
    },
    async redirect(redirect?: string) {
      let destination = redirect;
      if (!redirect) {
        destination = localStorage.getItem('redirect') ?? undefined;
        localStorage.removeItem('redirect');
      }

      await router.push(destination ?? '/dashboard');
    },
    async updateProfile(payload: Record<string, any>) {
      this.loading = true;

      try {
        await api.put(`/api/profile`, payload);
        await this.getCurrentUser();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async updatePassword(payload: { password: string; new_password: string; new_password_confirmation: string }) {
      this.loading = true;

      try {
        const response = await api.put(`/api/password`, payload);
        this.loading = false;
        return response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

      return false;
    },
    async forgotPassword(email: string) {
      this.loading = true;

      try {
        const response = await api.post(`/api/forgot-password`, { email });
        this.loading = false;
        return response;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async resetPassword(payload: { token: string; email: string; password: string; password_confirmation: string }) {
      this.loading = true;

      try {
        const response = await api.post(`/api/reset-password`, payload);
        this.loading = false;
        return response;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async endImpersonation() {
      try {
        await api.post(`/api/end-impersonation`);
        this.$reset();
      } catch (error) {
        console.log(error);
      }
    },
    async completeTutorial(participant_uuid: string) {
      if (!this.currentProgram || !this.currentProgram.participants || this.currentProgram.participants.length === 0) {
        return null;
      }

      this.currentProgram.participants[0].is_tutorial_complete = true;

      try {
        await api.patch(`/api/participants/${participant_uuid}`, {
          is_tutorial_complete: true,
        });
      } catch (error) {
        console.log(error);
      }
    },
    showTutorial() {
      if (!this.currentProgram || !this.currentProgram.participants || this.currentProgram.participants.length === 0) {
        return null;
      }

      this.currentProgram.participants[0].is_tutorial_complete = false;
    },
    async getResource(resource_uuid: string) {
      if (!this.currentProgram || !this.currentParticipant) {
        return null;
      }

      try {
        const response = await api.post(`/api/resources/${resource_uuid}/get`, {
          participant_uuid: this.currentParticipant.uuid,
        });
        const { url } = response.data;

        return url;
      } catch (error) {
        console.log(error);
      }
    },
    async saveSurveyPage(
      survey_uuid: string,
      page_uuid: string,
      response: {
        options: string[];
        text: string;
      },
    ) {
      if (!this.currentSurvey || !this.currentParticipant) {
        return null;
      }

      try {
        const apiResponse = await api.patch(`/api/surveys/${survey_uuid}`, {
          participant_uuid: this.currentParticipant.uuid,
          page_uuid,
          response: response,
        });

        const { completion } = apiResponse.data;

        if (completion.completed_at !== null && this.currentProgram) {
          this.surveyOpen = false;
          this.currentProgram.surveys = this.currentProgram.surveys.filter((survey) => survey.uuid !== survey_uuid);
        } else {
          this.currentSurvey.completion = completion;
        }

        return completion;
      } catch (error) {
        console.log(error);
      }

      return null;
    },
    async registerEmail(email: string, terms: boolean) {
      this.register.loading = true;
      this.register.invalid = false;

      try {
        const response = await api.post(`/api/register/email`, {
          email,
          terms,
        });

        this.register.email = email;
        this.register.verified = false;
      } catch (error) {
        this.register.email = '';
        this.register.verified = false;
        this.register.invalid = true;
      }

      this.register.loading = false;
      return null;
    },
    async resetEmail() {
      this.register.email = '';
      this.register.verified = false;
      this.register.registered = false;
    },
    async registerVerify(email: string, code: string) {
      this.register.loading = true;
      this.register.invalid = false;

      try {
        const response = await api.post(`/api/register/verify`, {
          email,
          code,
        });

        this.register.email = email;
        this.register.code = code;
        this.register.verified = true;
      } catch (error) {
        this.register.code = '';
        this.register.verified = false;
        this.register.invalid = true;
      }

      this.register.loading = false;
      return null;
    },
    async registerUser(email: string, code: string, profile: Record<string, any>) {
      this.register.loading = true;
      this.register.invalid = false;

      try {
        const response = await api.post(`/api/register/user`, {
          email,
          code,
          profile,
        });

        this.register.verified = true;
        this.register.registered = true;
        this.register.loading = false;

        const { success, url } = response.data;

        return { success, url };
      } catch (error) {
        this.register.code = '';
        this.register.verified = false;
        this.register.invalid = true;
      }

      this.register.loading = false;
      return null;
    },
    async startRefreshSessionTimer() {
      this.stopRefreshSessionTimer();

      this.sessionTimer = setInterval(async () => {
        try {
          const currentTime = new Date().getTime();
          await apiRetry.post(
            `/api/refresh-session?t=${currentTime}`,
            {},
            {
              timeout: 10000,
              'axios-retry': {
                retryCondition: () => true,
                shouldResetTimeout: true,
              },
            },
          );
        } catch (error) {
          console.log(error);
          await this.stopRefreshSessionTimer();
          this.sessionExpired = true;
          Sentry.captureException(error);
        }
      }, 30000);
    },
    async stopRefreshSessionTimer() {
      if (this.sessionTimer) {
        clearInterval(this.sessionTimer);
      }
    },
    selectProgram(program_uuid: string | null) {
      this.selected.program = program_uuid;
      this.selected.participant = null;
      this.selected.relationship = null;

      // Persist the selections in localStorage
      localStorage.setItem('selected', JSON.stringify(this.selected));
    },
    selectParticipant(participant_uuid: string | null) {
      this.selected.participant = participant_uuid;
      this.selected.relationship = null;

      // Persist the selections in localStorage
      localStorage.setItem('selected', JSON.stringify(this.selected));
    },
    selectRelationship(relationship_uuid: string | null) {
      this.selected.relationship = relationship_uuid;

      // Persist the selections in localStorage
      localStorage.setItem('selected', JSON.stringify(this.selected));
    },
    async downloadAllContent() {
      if (!this.currentProgram || !this.currentParticipant || !this.currentRelationship) {
        return null;
      }

      try {
        const response = await api.post(`/api/download-content`, {
          participant_uuid: this.currentParticipant.uuid,
          relationship_uuid: this.currentRelationship.uuid,
        });
        const { url } = response.data;

        return url;
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    totalRelationships(): number {
      return this.programs.reduce((total, program) => {
        total += program.participants.reduce((total, participant) => {
          total += participant.relationships.length;
          return total;
        }, 0);
        return total;
      }, 0);
    },
    currentProgram(): Program | null {
      if (this.programs && this.selected.program) {
        const program = this.programs.find((program) => program.uuid === this.selected.program);

        if (program) {
          return program;
        }
      }

      return null;
    },
    currentParticipant(): Participant | null {
      if (this.currentProgram && this.selected.participant) {
        const participant = this.currentProgram.participants.find(
          (participant) => participant.uuid === this.selected.participant,
        );

        if (participant) {
          return participant;
        }
      }

      return null;
    },
    currentRelationship(): Relationship | null {
      if (this.currentParticipant && this.selected.relationship) {
        const relationship = this.currentParticipant.relationships.find(
          (relationship) => relationship.uuid === this.selected.relationship,
        );

        if (relationship) {
          return relationship;
        }
      }

      return null;
    },
    currentPartner(): Partner | null {
      if (!this.currentRelationship || !this.currentRelationship.participants) {
        return null;
      }

      return this.currentRelationship.participants[0];
    },
    currentRole(): Role | null {
      if (!this.currentParticipant) {
        return null;
      }

      return this.currentParticipant.role;
    },
    currentRoleLabel(): string {
      if (!this.currentRole || !this.currentProgram) {
        return '';
      }

      return getRoleLabel(this.currentRole, this.currentProgram.type);
    },
    currentPartnerRole(): Role | null {
      if (!this.currentPartner) {
        return null;
      }

      return this.currentPartner.role;
    },
    currentPartnerRoleLabel(): string {
      if (!this.currentPartnerRole || !this.currentProgram) {
        return '';
      }

      return getRoleLabel(this.currentPartnerRole, this.currentProgram.type);
    },
    selectedParticipant(): Participant | null {
      if (this.currentProgram && this.selected.participant) {
        const participant = this.currentProgram.participants.find(
          (participant) => participant.uuid === this.selected.participant,
        );

        if (participant) {
          return participant;
        }
      }

      return null;
    },
    selectedParticipantIsActive(): boolean {
      if (!this.selectedParticipant) return false;

      return true;
      // return this.selectedParticipant.status === 'approved';
    },
    currentSurvey(): Survey | null {
      if (this.currentProgram && this.currentProgram.surveys.length) {
        return this.currentProgram.surveys[0];
      }

      return null;
    },
  },
});
