import type { Action } from '@tu/types/action';
import { api } from '@tu/utilities/api';
import { defineStore } from 'pinia';
import { type RouteLocationNormalized } from 'vue-router';
import { useToast } from 'vue-toast-notification';
import { useUserStore } from '.';

export const useActionStore = defineStore({
  id: 'action',
  state: () => ({
    loading: false,
    error: null as string | null,
    actions: [] as Action[],
  }),
  actions: {
    async hydrate(to: RouteLocationNormalized | null = null) {
      this.loading = true;
      try {
        const response = await api.get(`/api/actions`);

        const { actions } = response.data;
        this.actions = actions;
      } catch (error: any) {
        if (error.response.status !== 401) {
          // this.error = error;
        }
      } finally {
        this.loading = false;
      }
    },
    async dehydrate() {
      this.$reset();
    },
    async create(payload: {
      text: string;
      assignee_uuid: string;
      goal_uuids: string[];
      session_uuid?: string;
    }): Promise<Action | null> {
      const $toast = useToast();
      const userStore = useUserStore();
      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return null;
      }

      // Save the action
      this.loading = true;

      try {
        const response = await api.post(`/api/actions`, {
          ...payload,
          participant_uuid: userStore.currentParticipant.uuid,
          relationship_uuid: userStore.currentRelationship.uuid,
        });

        const { action } = response.data;

        // Add the action to the store
        this.actions = [...this.actions, action];

        this.loading = false;
        $toast.success('Your action has been saved.', { position: 'bottom' });

        return action;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

      return null;
    },
    async update(
      action_uuid: string,
      payload: { text: string; assignee_uuid: string; goal_uuids: string[] },
    ): Promise<Action | null> {
      const $toast = useToast();
      const userStore = useUserStore();
      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return null;
      }

      // Save the action
      this.loading = true;

      try {
        const response = await api.patch(`/api/actions/${action_uuid}`, {
          ...payload,
        });

        const { action } = response.data;

        // Update the action in the store
        const index = this.actions.findIndex((action) => action.uuid === action_uuid);
        if (index !== -1) {
          this.actions[index] = action;
        } else {
          this.actions.push(action);
        }

        this.loading = false;
        $toast.success('Your action has been saved.', { position: 'bottom' });

        return action;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

      return null;
    },
    async markComplete(action_uuid: string, is_complete: boolean): Promise<Action | null> {
      const userStore = useUserStore();
      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return null;
      }

      // Save the action
      this.loading = true;

      try {
        const response = await api.patch(`/api/actions/${action_uuid}/mark-complete`, {
          is_complete,
        });

        const { action } = response.data;

        // Update the action in the store
        const index = this.actions.findIndex((action) => action.uuid === action_uuid);
        if (index !== -1) {
          this.actions[index] = action;
        } else {
          this.actions.push(action);
        }

        this.loading = false;

        return action;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

      return null;
    },
    async delete(action_uuid: string): Promise<boolean> {
      const $toast = useToast();
      const userStore = useUserStore();
      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return false;
      }

      // Save the action
      this.loading = true;

      try {
        await api.delete(`/api/actions/${action_uuid}`);

        // Update the action in the store
        const index = this.actions.findIndex((action) => action.uuid === action_uuid);
        if (index !== -1) {
          this.actions.splice(index, 1);
        }

        this.loading = false;
        $toast.success('Your action has been deleted.', { position: 'bottom' });

        return true;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

      return false;
    },
    getActionsForSession(session_uuid: string): Action[] {
      return this.actions.filter((action) => action.session_uuid === session_uuid);
    },
  },
  getters: {
    currentActions(): Action[] {
      const userStore = useUserStore();
      if (!userStore.currentProgram || !userStore.currentParticipant || !userStore.currentRelationship) {
        return [];
      }

      return this.actions.filter(
        (action) =>
          action.program_uuid === userStore.currentProgram?.uuid &&
          action.relationship_uuid === userStore.currentRelationship?.uuid,
      );
    },
  },
});
