import axios from 'axios';
import axiosRetry from 'axios-retry';

export const api = axios.create({
  baseURL: axios.defaults.baseURL,
  withCredentials: true,
});

api.interceptors.response.use((response) => {
  // Follow redirect if present
  if (response.data.redirect) {
    window.location.href = response.data.redirect;
  }

  return response;
});

export const apiRetry = axios.create({
  baseURL: axios.defaults.baseURL,
  withCredentials: true,
});

apiRetry.interceptors.response.use((response) => {
  // Follow redirect if present
  if (response.data.redirect) {
    window.location.href = response.data.redirect;
  }

  return response;
});

axiosRetry(apiRetry, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
});
