// Check if window.Beacon is exists

export const beaconIdentify = (data: any) => {
  let currentBeaconUser = {};
  const currentBeaconInfo = (window as any).Beacon('info');

  if (currentBeaconInfo) {
    currentBeaconUser = {
      name: currentBeaconInfo.visitor?.name,
      email: currentBeaconInfo.visitor?.email,
      company: currentBeaconInfo.visitor?.properties?.company,
      client: currentBeaconInfo.visitor?.properties?.client,
      role: currentBeaconInfo.visitor?.properties?.role,
    };
  }
  (window as any).Beacon('identify', {
    ...currentBeaconUser,
    ...data,
  });
};
