import { api } from '@tu/utilities/api';
import { defineStore } from 'pinia';
import { type RouteLocationNormalized } from 'vue-router';
import type { fieldTypes } from '../utilities/fields';

interface Program {
  name: string;
  about_content: string;
  application_instructions: string;
  application_thank_you: string;
  mentor_accepted: boolean;
  mentee_accepted: boolean;
  type: string;
}

export interface ApplicationSection {
  uuid: string;
  name: string;
  questions: ApplicationQuestion[];
}

interface ApplicationQuestion {
  uuid: string;
  type: keyof typeof fieldTypes;
  label: string;
  instructions: string | null;
  required: boolean;
  settings: any;
}

type Role = 'mentor' | 'mentee';

interface InviteStoreState {
  uuid: string | null;
  organisation_uuid: string | null;
  valid: boolean;
  error: string | null;
  data: {
    program: Program | null;
    allowedRoles: Role[];
    availableRoles: Role[];
    selectedRole: Role | null;
    menteeApplication: ApplicationSection[];
    mentorApplication: ApplicationSection[];
    applicationLoading: boolean;
    applied: boolean;
    additionalSettings: any;
  };
}

export const useInviteStore = defineStore({
  id: 'invite',
  state: (): InviteStoreState => {
    return {
      uuid: null,
      organisation_uuid: null,
      valid: false,
      error: null,
      data: {
        program: null,
        allowedRoles: [],
        availableRoles: [],
        selectedRole: null,
        menteeApplication: [],
        mentorApplication: [],
        applicationLoading: false,
        applied: false,
        additionalSettings: null,
      },
    };
  },
  actions: {
    async hydrate(to: RouteLocationNormalized | null = null) {
      if (!this.uuid) {
        if (!to || to.name !== 'invite') {
          return;
        }
        this.uuid = to?.params.uuid as string;
        this.organisation_uuid = (to?.params.organisationUuid as string | null) || null;
      }

      const direct = to?.query.direct === 'true';

      await this.getInvite(this.uuid, this.organisation_uuid, direct);
    },
    async dehydrate() {
      this.valid = false;
      this.error = null;
      this.data = {
        program: null,
        allowedRoles: [],
        availableRoles: [],
        selectedRole: null,
        menteeApplication: [],
        mentorApplication: [],
        applicationLoading: false,
        applied: false,
        additionalSettings: null,
      };
    },
    async getInvite(uuid: string, organisationUuid: string | null = null, direct = false) {
      try {
        const response = await api.get(
          (organisationUuid ? `/api/invites/${uuid}/${organisationUuid}` : `/api/invites/${uuid}`) +
            (direct ? '?direct=true' : ''),
        );

        const { valid, data, message } = response.data;

        this.valid = valid;

        if (!valid) {
          this.error = message;
          return;
        }

        const { program, mentee_application, mentor_application, allowed_roles, available_roles, additional_settings } =
          data;
        this.data = {
          ...this.data,
          program,
          allowedRoles: allowed_roles,
          availableRoles: available_roles,
          menteeApplication: mentee_application,
          mentorApplication: mentor_application,
          additionalSettings: additional_settings,
        };
      } catch (error: any) {
        this.error = error;
      }
    },
    async submitApplication({ role, application }: { role: 'mentor' | 'mentee'; application: [] }) {
      this.data.applicationLoading = true;

      try {
        const response = await api.post(`/api/invites/${this.uuid}/apply/${this.organisation_uuid}`, {
          role: role,
          application: application,
        });

        const { valid, data, message } = response.data;

        if (!valid) {
          this.error = message;
        }

        this.data.applicationLoading = false;
        this.data.applied = true;

        return data;
      } catch (error) {
        console.log(error);
        this.data.applicationLoading = false;
      }
    },
  },
});
