<template>
  <component
    :is="component"
    :class="
      cn(
        'group inline-flex items-center justify-between rounded-br-full rounded-tl-full rounded-tr-full border border-0 text-sm shadow disabled:cursor-not-allowed',
        presets[preset].button,
        direction === 'left' ? 'flex-row-reverse' : '',
      )
    "
    :type="type"
    :disabled="disabled"
    v-bind="additionalProps"
    @click="onClick"
  >
    <div class="grow text-center" :class="direction === 'left' ? `pr-${paddingValue}` : `pl-${paddingValue}`">
      <slot></slot>
    </div>
    <div
      class="rounded-br-full rounded-tl-full rounded-tr-full"
      :class="
        cn(
          icon ? presets[preset].icon : '',
          { 'p-3': props.size === 'md', 'p-2': props.size === 'sm' },
          icon ? (direction === 'left' ? 'mr-4' : 'ml-4') : '',
        )
      "
    >
      <slot v-if="loading" name="loading">
        <svg class="h-4 w-4 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </slot>
      <slot v-else-if="icon" name="icon">
        <component :is="icon" class="h-4 w-4" />
      </slot>
      <slot v-else-if="!icon" name="icon">
        <div class="h-4 w-4" />
      </slot>
    </div>
  </component>
</template>

<script setup lang="ts">
import { cn } from '@tu/utilities/cn';
import type { FunctionalComponent } from 'vue';
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import MicrophoneBlockIcon from './icons/MicrophoneBlockIcon.vue';

interface Props {
  /** Disables the button */
  disabled?: boolean;
  /** To what internal link the button should direct */
  to?: RouteLocationRaw;
  /** To what external link the button should direct */
  href?: string;
  target?: string;
  /** Show a circular progress bar */
  loading?: boolean;
  /** Element type to be used */
  type?: string;
  /** Optional icon component */
  icon?: string | FunctionalComponent | typeof MicrophoneBlockIcon;
  /** Style preset */
  preset?: keyof typeof presets;
  /** Size */
  size?: 'sm' | 'md' | 'lg';
  /** Direction */
  direction?: 'left' | 'right';

  largerPadding?: boolean;
}

const presets = {
  primary: {
    button: 'bg-serendis-navy/90 text-white hover:bg-serendis-navy disabled:bg-serendis-navy/60',
    icon: 'bg-white/20 text-white group-hover:bg-white/20',
  },
  secondary: {
    button: 'bg-white text-serendis-navy hover:bg-gray-50 disabled:bg-gray-200',
    icon: 'bg-serendis-navy text-white group-hover:bg-serendis-navy/90',
  },
  red: {
    button: 'border border-red-500 bg-white text-red-500 hover:text-white hover:bg-red-500 disabled:bg-red-200',
    icon: 'bg-red-500 text-white group-hover:bg-black/10',
  },
  teal: {
    button: 'bg-serendis-teal/90 text-white hover:bg-serendis-teal disabled:bg-serendis-teal/60',
    icon: 'bg-black/10 text-white group-hover:bg-black/20',
  },
  'light-green': {
    button: 'bg-serendis-light-green/90 text-white hover:bg-serendis-light-green disabled:bg-serendis-light-green/60',
    icon: 'bg-black/10 text-white group-hover:bg-black/20',
  },
  orange: {
    button: 'bg-serendis-orange/90 text-white hover:bg-serendis-orange disabled:bg-serendis-orange/60',
    icon: 'bg-white/20 text-white group-hover:bg-black/20',
  },
  'pale-blue': {
    button:
      'bg-serendis-pale-blue/90 text-white hover:bg-serendis-pale-blue disabled:bg-serendis-pale-blue/60 text-serendis-navy',
    icon: 'bg-white/20 text-serendis-navy group-hover:bg-black/10',
  },
  'orange-inverse': {
    button: 'bg-white text-serendis-orange hover:bg-white/90 disabled:bg-gray-200',
    icon: 'bg-serendis-orange text-white group-hover:bg-serendis-orange/90',
  },
};

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  to: '',
  href: undefined,
  target: '_blank',
  loading: false,
  type: 'button',
  icon: undefined,
  preset: 'primary',
  size: 'md',
  direction: 'right',
  largerPadding: false,
});

const emit = defineEmits(['click']);

const component = computed(() => {
  if (props.disabled) return 'button';
  if (props.href) return 'a';
  if (props.to) return 'router-link';

  return 'button';
});

const additionalProps = computed(() => {
  if (props.to) {
    return {
      to: props.to,
    };
  }

  if (component.value === 'a') {
    return {
      href: props.href,
      target: props.target,
      rel: 'noopener noreferrer',
    };
  }

  return {};
});

const paddingValue = computed(() => {
  if (props.largerPadding) {
    return 10;
  }

  return 8;
});

async function onClick(event: MouseEvent) {
  if (props.loading === true) return;
  emit('click', event);
}
</script>
