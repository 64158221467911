import type { RouteRecordRaw } from 'vue-router';
import EmptyLayout from '../views/layouts/EmptyLayout.vue';

const routes = [
  {
    path: '/',
    meta: { requiresAuth: true },
    component: () => import('@tu/views/layouts/auth/AuthLayout.vue'),
    children: [
      {
        name: 'default',
        path: '',
        redirect: { name: 'home' },
      },
      {
        name: 'portal',
        path: '/portal',
        meta: { requiresAuth: true, layoutProps: { showSidebar: false, showBackButton: false, showHeader: false } },
        component: () => import('@tu/views/pages/Portal.vue'),
      },
      {
        name: 'profile',
        path: '/profile',
        meta: { requiresAuth: true, layoutProps: { showSidebar: false, showBackButton: true } },
        component: () => import('@tu/views/pages/Profile.vue'),
      },
      {
        name: 'password',
        path: '/password',
        meta: { requiresAuth: true, layoutProps: { showSidebar: false, showBackButton: true } },
        component: () => import('@tu/views/pages/Password.vue'),
      },
      {
        name: 'home',
        path: '/home',
        meta: { requiresAuth: true, layoutProps: { showSidebar: false, showHeaderDetails: false } },
        component: () => import('@tu/views/pages/home/Home.vue'),
      },
      {
        name: 'dashboard',
        path: '/dashboard',
        meta: { requiresAuth: true },
        component: () => import('@tu/views/pages/dashboard/Dashboard.vue'),
      },
      {
        name: 'goals-and-actions',
        path: '/goals-and-actions',
        meta: { requiresAuth: true, layoutProps: { activeStep: 'goals-and-actions' } },
        component: () => import('@tu/views/pages/goals-and-actions/Page.vue'),
      },
      {
        name: 'keys.keys.index',
        path: '/keys',
        meta: { requiresAuth: true, layoutProps: { activeStep: 'keys' } },
        component: () => import('@tu/views/pages/keys/Keys.vue'),
      },
      {
        name: 'keys.keys.view',
        path: '/keys/:uuid',
        meta: { requiresAuth: true, layoutProps: { activeStep: 'keys' } },
        component: () => import('@tu/views/pages/keys/KeysView.vue'),
      },
      {
        name: 'keys.notes.index',
        path: '/key-notes',
        meta: { requiresAuth: true, layoutProps: { activeStep: 'keys' } },
        component: () => import('@tu/views/pages/keynotes/KeyNotesList.vue'),
      },
      {
        name: 'keys.notes.view',
        path: '/key-notes/:uuid',
        meta: { requiresAuth: true, layoutProps: { activeStep: 'keys' } },
        component: () => import('@tu/views/pages/keynotes/KeyNoteView.vue'),
      },
      {
        name: 'keys.notes.shared-view',
        path: '/shared-key-notes/:uuid',
        meta: { requiresAuth: true, layoutProps: { activeStep: 'keys' } },
        component: () => import('@tu/views/pages/keynotes/SharedKeyNoteView.vue'),
      },
      {
        name: 'sessions.index',
        path: '/sessions',
        meta: { requiresAuth: true, layoutProps: { activeStep: 'sessions' } },
        component: () => import('@tu/views/pages/sessions/Sessions.vue'),
      },
      {
        name: 'sessions.view',
        path: '/sessions/:uuid/:tab?',
        meta: { requiresAuth: true, layoutProps: { activeStep: 'sessions' } },
        component: () => import('@tu/views/pages/sessions/SessionView.vue'),
      },
      {
        name: 'program.resources',
        path: '/program/resources',
        meta: { requiresAuth: true },
        component: () => import('@tu/views/pages/program/ProgramResources.vue'),
      },
      {
        name: 'program.profile',
        path: '/program/profile',
        meta: { requiresAuth: true },
        component: () => import('@tu/views/pages/program/ProgramProfile.vue'),
      },
      {
        name: 'program.partner',
        path: '/program/partner',
        meta: { requiresAuth: true },
        component: () => import('@tu/views/pages/program/ProgramPartnerProfile.vue'),
      },
    ],
  },
  {
    name: 'login',
    path: '/login',
    meta: { public: true },
    component: () => import('@tu/views/pages/auth/Login.vue'),
  },
  {
    name: 'register',
    path: '/register',
    meta: { public: true },
    component: () => import('@tu/views/pages/auth/Register.vue'),
  },
  {
    name: 'reset-password',
    path: '/reset-password/:token?',
    meta: { public: true },
    component: () => import('@tu/views/pages/auth/ResetPassword.vue'),
  },
  {
    name: 'invite',
    path: '/invite/:uuid/:organisationUuid?',
    meta: { requiresAuth: false, layout: EmptyLayout, public: true },
    component: () => import('@tu/views/pages/invite/Invite.vue'),
  },
  {
    name: 'terms-of-use',
    path: '/terms-of-use',
    meta: { public: true },
    component: () => import('@tu/views/pages/auth/TermsOfUse.vue'),
  },
  {
    name: 'privacy-policy',
    path: '/privacy-policy',
    meta: { public: true },
    component: () => import('@tu/views/pages/auth/PrivacyPolicy.vue'),
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    meta: { layout: EmptyLayout },
    component: () => import('@tu/views/pages/errors/NotFound.vue'),
  },
] as RouteRecordRaw[];

export default routes;
