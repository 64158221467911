import { createRouter, createWebHistory, type RouteLocationNormalized } from 'vue-router';

import routes from '@tu/router/routes';

import {
  useActionStore,
  useGlobalStore,
  useGoalStore,
  useInstanceStore,
  useInviteStore,
  useKeyNoteStore,
  useKeyStore,
  useSessionStore,
  useUserStore,
} from '@tu/stores';

export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes,
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();

  // if (!authStore.user) {
  //   await authStore.getCurrentUser();
  // }

  // if (!authStore.loggedIn && to.meta.requiresAuth) {
  //   return next({
  //     name: 'login',
  //   });
  // }

  // if (authStore.loggedIn && to.meta.requiresGuest) {
  //   return next({
  //     name: 'dashboard',
  //   });
  // }

  await hydrate(to);

  if (to.meta?.public !== true && !userStore.user) {
    return next({
      name: 'login',
      query: {
        redirect: to.fullPath, // May need to URL encode this
      },
    });
  }

  next();
});

router.afterEach(() => {
  const globalStore = useGlobalStore();
  // Scroll to top of page
  if (globalStore.main) {
    globalStore.main.scrollTo({ top: 0, behavior: 'smooth' });
  }
});

/**
 * These stores are hydrated in parallel after the user store has been hydrated.
 */
const stores = [useGoalStore, useKeyStore, useKeyNoteStore, useActionStore, useSessionStore];

export const hydrate = async (to: RouteLocationNormalized | null = null) => {
  const globalStore = useGlobalStore();
  const userStore = useUserStore();
  const instanceStore = useInstanceStore();
  const inviteStore = useInviteStore();

  if (globalStore.hydrated || globalStore.hydrating) {
    return;
  }

  globalStore.hydrating = true;

  try {
    /**
     * @NOTE
     * Some stores rely on the userStore and instanceStore to be set, so they can fetch user-specific data. The
     * following makes sure that the auth store is always fetched first, before we hydrate anything
     * else.
     */
    await Promise.all([instanceStore.hydrate(to), userStore.hydrate(to), inviteStore.hydrate(to)]);

    /**
     * @NOTE
     * If the user is not logged in, we don't need to hydrate any other stores.
     */
    if (!userStore.user) {
      globalStore.hydrated = true;
      globalStore.hasBeenHydrated = true;
      return;
    }

    await Promise.all(stores.map((store) => store().hydrate(to)));
  } catch (error: any) {
    globalStore.error = error;
  } finally {
    globalStore.hydrating = false;
  }

  globalStore.hydrated = true;
  globalStore.hasBeenHydrated = true;
};

export const dehydrate = async () => {
  const globalStore = useGlobalStore();
  const userStore = useUserStore();

  try {
    await userStore.dehydrate();

    await Promise.all(stores.map((store) => store().dehydrate()));
  } catch (error: any) {
    globalStore.error = error;
  }

  globalStore.hydrated = false;
};

export const rehydrate = async () => {
  await dehydrate();
  await hydrate();
};

export default router;
