import type { Goal } from '@tu/types/goal';
import { api } from '@tu/utilities/api';
import { defineStore } from 'pinia';
import { type RouteLocationNormalized } from 'vue-router';
import { useToast } from 'vue-toast-notification';
import { useUserStore } from '.';

export const useGoalStore = defineStore({
  id: 'goal',
  state: () => ({
    loading: false,
    error: null as string | null,
    goals: [] as Goal[],
  }),
  actions: {
    async hydrate(to: RouteLocationNormalized | null = null) {
      this.loading = true;
      try {
        const response = await api.get(`/api/goals`);

        const { goals } = response.data;
        this.goals = goals;
      } catch (error: any) {
        if (error.response.status !== 401) {
          // this.error = error;
        }
      } finally {
        this.loading = false;
      }
    },
    async dehydrate() {
      this.$reset();
    },
    async save(payload: { uuid?: string; text: string; type: string }): Promise<Goal | null> {
      const $toast = useToast();
      const userStore = useUserStore();
      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return null;
      }

      // Save the goal
      this.loading = true;

      try {
        const response = await api.post(`/api/goals`, {
          ...payload,
          participant_uuid: userStore.currentParticipant.uuid,
          relationship_uuid: userStore.currentRelationship.uuid,
        });

        const { goal } = response.data;

        // Update the goal in the store
        const index = this.goals.findIndex((goal) => goal.uuid === payload.uuid);
        if (index !== -1) {
          this.goals[index] = goal;
        } else {
          this.goals.push(goal);
        }

        this.loading = false;
        $toast.success('Your goal has been saved.', { position: 'bottom' });

        return goal;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

      return null;
    },
    async delete(goal_uuid: string) {
      const $toast = useToast();
      const userStore = useUserStore();
      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return false;
      }

      this.loading = true;
      try {
        await api.delete(`/api/goals/${goal_uuid}`);
        this.goals = this.goals.filter((goal) => goal.uuid !== goal_uuid);
        this.loading = false;
        $toast.success('Your goal has been deleted.', { position: 'bottom' });
        return true;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }

      return true;
    },
  },
  getters: {
    aspirationalGoal(): Goal | null {
      const userStore = useUserStore();
      if (!userStore.currentRelationship) {
        return null;
      }

      return (
        this.goals.find(
          (goal) => goal.type === 'aspirational' && goal.relationship_uuid === userStore.currentRelationship?.uuid,
        ) || null
      );
    },
    pillarGoals(): Goal[] {
      const userStore = useUserStore();
      if (!userStore.currentRelationship) {
        return [];
      }

      return this.goals.filter(
        (goal) => goal.type === 'pillar' && goal.relationship_uuid === userStore.currentRelationship?.uuid,
      );
    },
  },
});
