import type { KeyNote, SharedKeyNote } from '@tu/types/key';
import { api } from '@tu/utilities/api';
import { defineStore } from 'pinia';
import { type RouteLocationNormalized } from 'vue-router';
import { useGoalStore, useKeyStore, useUserStore } from '.';
import * as Sentry from '@sentry/vue';

export const useKeyNoteStore = defineStore({
  id: 'key_note',
  state: () => ({
    loading: false,
    error: null as string | null,
    key_notes: [] as KeyNote[],
    shared_key_notes: [] as SharedKeyNote[],
  }),
  actions: {
    async hydrate(to: RouteLocationNormalized | null = null) {
      this.loading = true;
      try {
        const response = await api.get(`/api/key-notes`);

        const { key_notes, shared_key_notes } = response.data;
        this.key_notes = key_notes;
        this.shared_key_notes = shared_key_notes;
      } catch (error: any) {
        if (error.response.status !== 401) {
          // this.error = error;
        }
      } finally {
        this.loading = false;
      }
    },
    async dehydrate() {
      this.$reset();
    },
    findKeyNote(uuid: string): KeyNote | undefined {
      return this.key_notes.find((key_note) => key_note.uuid === uuid);
    },
    findSharedKeyNote(uuid: string): SharedKeyNote | undefined {
      return this.shared_key_notes.find((key_note) => key_note.uuid === uuid);
    },
    findKeyNotesForKey(key_uuid: string): KeyNote[] {
      const userStore = useUserStore();

      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return [];
      }

      return this.key_notes.filter((key_note) => {
        if (!userStore.currentRelationship || !userStore.currentParticipant) {
          return false;
        }

        return (
          key_note.key_uuid === key_uuid &&
          key_note.participant_uuid === userStore.currentParticipant.uuid &&
          key_note.relationship_uuid === userStore.currentRelationship.uuid
        );
      });
    },
    findKeyNoteAnswerForBlock(key_note: KeyNote, block_uuid: string) {
      return key_note.answers.find((answer) => answer.key_block_uuid === block_uuid);
    },
    async createKeyNote(key_uuid: string) {
      const userStore = useUserStore();
      if (!userStore.currentRelationship || !userStore.currentParticipant) {
        return null;
      }

      try {
        const response = await api.post(`/api/key-notes`, {
          key_uuid,
          participant_uuid: userStore.currentParticipant.uuid,
          relationship_uuid: userStore.currentRelationship.uuid,
        });

        const { key_note } = response.data;
        this.key_notes.push(key_note);

        return key_note;
      } catch (error) {
        console.log(error);
      }

      return null;
    },
    async saveKeyNotePage(key_note_uuid: string, key_page_uuid: string, key_blocks_answers: Record<string, any>) {
      try {
        const response = await api.patch(`/api/key-notes/${key_note_uuid}`, {
          key_page_uuid,
          key_blocks_answers: key_blocks_answers,
        });

        const { key_note } = response.data;

        // If any blocks updates goals, refresh the goal store
        const keyStore = useKeyStore();
        const key = keyStore.findKey(key_note.key_uuid);
        if (key) {
          const updatesGoals =
            Object.keys(key_blocks_answers)
              .map((key_block_uuid) => {
                return keyStore.findKeyBlock(key, key_block_uuid);
              })
              .filter((key_block) => {
                return (
                  key_block &&
                  (key_block.options?.create_goal_aspirational === true ||
                    key_block.options?.create_goal_pillar === true)
                );
              }).length > 0;

          if (updatesGoals) {
            const goalStore = useGoalStore();
            await goalStore.hydrate();
          }
        }

        // Update the key note in the store
        const index = this.key_notes.findIndex((key_note) => key_note.uuid === key_note_uuid);
        if (index !== -1) {
          this.key_notes[index] = key_note;
        } else {
          this.key_notes.push(key_note);
        }

        return key_note;
      } catch (error) {
        console.log(error);
      }

      return null;
    },
    async previousKeyNotePage(key_note_uuid: string) {
      try {
        const response = await api.patch(`/api/key-notes/${key_note_uuid}/previous`);

        const { key_note } = response.data;

        // Update the key note in the store
        const index = this.key_notes.findIndex((key_note) => key_note.uuid === key_note_uuid);
        if (index !== -1) {
          this.key_notes[index] = key_note;
        } else {
          this.key_notes.push(key_note);
        }

        return key_note;
      } catch (error) {
        console.log(error);
      }

      return null;
    },
    async saveKeyNoteRating(key_note_uuid: string, rating: number) {
      try {
        const response = await api.patch(`/api/key-notes/${key_note_uuid}/rate`, {
          rating,
        });

        const { key_note } = response.data;

        // Update the key note in the store
        const index = this.key_notes.findIndex((key_note) => key_note.uuid === key_note_uuid);
        if (index !== -1) {
          this.key_notes[index] = key_note;
        } else {
          this.key_notes.push(key_note);
        }

        return key_note;
      } catch (error) {
        console.log(error);
      }

      return null;
    },
    async setKeyNoteShared(key_note_uuid: string, shared: boolean) {
      try {
        const response = await api.patch(`/api/key-notes/${key_note_uuid}/share`, {
          shared,
        });

        const { key_note } = response.data;

        // Update the key note in the store
        const index = this.key_notes.findIndex((key_note) => key_note.uuid === key_note_uuid);
        if (index !== -1) {
          this.key_notes[index] = key_note;
        } else {
          this.key_notes.push(key_note);
        }

        return key_note;
      } catch (error) {
        console.log(error);
      }

      return null;
    },
    isKeyNoteComplete(key_note: KeyNote): boolean {
      return key_note.progress === 100 && key_note.rating !== null && key_note.completed_at !== null;
    },
    updateKeyNote(
      key_note_uuid: string,
      payload: {
        session_uuid?: string | null;
      },
    ): KeyNote | null {
      const index = this.key_notes.findIndex((key_note) => key_note.uuid === key_note_uuid);
      if (index !== -1) {
        this.key_notes[index] = {
          ...this.key_notes[index],
          ...payload,
        };

        return this.key_notes[index];
      }

      return null;
    },
    async downloadKeyNote(key_note_uuid: string) {
      try {
        const response = await api.get(`/api/key-notes/${key_note_uuid}/download`);
        const { url } = response.data;

        // Redirect to the URL in a new tab
        window.open(url, '_blank');

        return true;
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }

      return null;
    },
  },
  getters: {
    /**
     * Gets the key notes for the current relationship.
     */
    currentKeyNotes(): KeyNote[] {
      const userStore = useUserStore();
      const currentRelationship = userStore.currentRelationship;

      if (!currentRelationship) {
        return [];
      }

      return this.key_notes.filter((key_note) => key_note.relationship_uuid === currentRelationship.uuid);
    },
    currentKeyNotesSelf(): KeyNote[] {
      const userStore = useUserStore();
      const currentParticipant = userStore.currentParticipant;

      if (!currentParticipant) {
        return [];
      }

      const currentKeyNotes = this.currentKeyNotes;

      return currentKeyNotes.filter((key_note) => key_note.participant_uuid === currentParticipant.uuid);
    },
    currentKeyNotesShared(): SharedKeyNote[] {
      const userStore = useUserStore();
      const currentRelationship = userStore.currentRelationship;

      if (!currentRelationship) {
        return [];
      }

      return this.shared_key_notes.filter((key_note) => key_note.relationship_uuid === currentRelationship.uuid);
    },
    currentKeyNotesSelfCompleted(): KeyNote[] {
      return this.currentKeyNotesSelf.filter((key_note) => key_note.progress === 100 && key_note.rating !== null);
    },
  },
});
