import * as Sentry from '@sentry/vue';
import type { Instance } from '@tu/types/instance';
import { api } from '@tu/utilities/api';
import { beaconIdentify } from '@tu/utilities/helpscout';
import { defineStore } from 'pinia';
import { type RouteLocationNormalized } from 'vue-router';

export const useInstanceStore = defineStore({
  id: 'instance',
  state: () => ({
    loading: false,
    error: null as string | null,
    instance: null as Instance | null,
    session: null as {
      invite_uuid?: string;
    } | null,
  }),
  actions: {
    async hydrate(to: RouteLocationNormalized | null = null) {
      this.loading = true;
      try {
        const response = await api.get(`/api/instance`);

        const { instance, session } = response.data;
        this.instance = instance;
        this.session = session;

        Sentry.setTag('environment', instance.environment);
        Sentry.setTag('instance', instance.name);

        beaconIdentify({
          company: instance.name,
          client: instance.name,
        });
      } catch (error: any) {
        if (error.response.status !== 401) {
          // this.error = error;
        }
      } finally {
        this.loading = false;
      }
    },
    async dehydrate() {
      this.$reset();
    },
  },
  getters: {
    isProduction(): boolean {
      return this.instance?.environment === 'production';
    },
    instanceType(): 'mentorkey' | 'sponsorkey' {
      if (this.instance?.host?.includes('sponsorkey')) {
        return 'sponsorkey';
      }

      return 'mentorkey';
    },
  },
});
